//Components large

import NavBar from "../largeDisplay/layout/NavBar";

//Components small

import NavBarSmall from "../smallDisplay/layout/NavBarSmall";

//MUI
import { Box } from "@mui/material";

function NavBarElement() {
    return (
        <Box>
            <Box sx={{ display: { xs: "none", md: "block" } }}>
                <NavBar />
            </Box>
            <Box sx={{ display: { xs: "block", md: "none" } }}>
                <NavBarSmall />
            </Box>
        </Box>
    );
}

export default NavBarElement;
