import { Route, Routes } from "react-router-dom";
import { theme } from "./Theme";

//Elements

import HomeElement from "./components/elements/HomeElement";
import ContactElement from "./components/elements/ContactElement";
import ClassesElement from "./components/elements/ClassesElement";
import GalerieElement from "./components/elements/GalerieElement";
import CoachesElement from "./components/elements/CoachesElement";

import NavBarElement from "./components/elements/NavBarElement";
import FooterElement from "./components/elements/FooterElement";

//MUI
import { ThemeProvider } from "@mui/material";

function App() {
  return (
    <ThemeProvider theme={theme}>
      <NavBarElement />
      <Routes>
        <Route path="/" element={<HomeElement />} />
        <Route path="/classes" element={<ClassesElement />} />
        <Route path="/coaches" element={<CoachesElement />} />
        <Route path="/galerie" element={<GalerieElement />} />
        <Route path="/contact" element={<ContactElement />} />
      </Routes>
      <FooterElement />
    </ThemeProvider>
  );
}

export default App;
