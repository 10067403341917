//Components large

import GaleriePage from "../largeDisplay/pages/GaleriePage";

//Components small

import GaleriePageSmall from "../smallDisplay/pages/GaleriePageSmall";

//MUI
import { Box } from "@mui/material";

function GalerieElement() {
    return (
        <Box>
            <Box sx={{ display: { xs: "none", md: "block" } }}>
                <GaleriePage />
            </Box>
            <Box sx={{ display: { xs: "block", md: "none" } }}>
                <GaleriePageSmall />
            </Box>
        </Box>
    );
}

export default GalerieElement;
