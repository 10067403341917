//Components large

import HomePage from "../largeDisplay/pages/HomePage";

//Components small

import HomePageSmall from "../smallDisplay/pages/HomePageSmall";

//MUI
import { Box } from "@mui/material";

function HomeElement() {
    return (
        <Box>
            <Box sx={{ display: { xs: "none", md: "block" } }}>
                <HomePage />
            </Box>
            <Box sx={{ display: { xs: "block", md: "none" } }}>
                <HomePageSmall />
            </Box>
        </Box>
    );
}

export default HomeElement;
