import { Box } from "@mui/material";

export default function MapCustomSmall() {
    return (
        <Box border={2} margin={2} sx={{ width: "100%", height: "75%" }}>
            <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d22358.412532829894!2d-73.50904244027116!3d45.53419858077724!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4cc91b99f0b1a597%3A0xdd72018ccb0a59d9!2sEsdl%20School%20Shotokan%20Dojo%20Longueuil!5e0!3m2!1sen!2sca!4v1679590983390!5m2!1sen!2sca"
                display="block"
                width="100%"
                height="300rem"
                style={{ border: "0" }}
                allowFullScreen={false}
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
        </Box>
    );
}
