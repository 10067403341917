//MUI
import { Box, Button, Typography } from "@mui/material";

//Components
import MapCustom from "../contact/MapCustom";

export default function ContactPage() {
    return (
        <Box display="flex" height="calc(100vh - 100px)" alignItems="center">
            <Box
                padding={2}
                width="100vw"
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="flex-start"
            >
                <Typography
                    variant="h3"
                    sx={{ fontWeight: "bold", fontSize: "50px" }}
                >
                    Contactez-nous!
                </Typography>
                <br />
                <Typography sx={{ fontSize: "30px" }}>
                    Pour toutes questions concernant nos cours ou pour planifier
                    un cours d'essai, appelez-nous au:
                    <Button onClick={() => window.open("tel:5146680275")}>
                        <Typography
                            sx={{ fontWeight: "bold", fontSize: "30px" }}
                        >
                            514-668-0275
                        </Typography>
                    </Button>
                    Ou écrivez-nous au:
                    <Button
                        onClick={() => {
                            window.location.href = "mailto:sseguin007@live.ca";
                        }}
                    >
                        <Typography
                            sx={{ fontWeight: "bold", fontSize: "30px" }}
                        >
                            sseguin007@live.ca
                        </Typography>
                    </Button>
                </Typography>
                <br />
                <Typography sx={{ fontSize: "30px" }}>
                    Nous sommes situés au 1077 Chemin de Chambly à Longueuil
                    près du Cégep Édouard-Montpetit. N'hésitez pas à venir faire
                    un tour!
                </Typography>
            </Box>
            <MapCustom />
        </Box>
    );
}
