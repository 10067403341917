//Components large

import ContactPage from "../largeDisplay/pages/ContactPage";

//Components small

import ContactPageSmall from "../smallDisplay/pages/ContactPageSmall";

//MUI
import { Box } from "@mui/material";

function ContactElement() {
    return (
        <Box>
            <Box sx={{ display: { xs: "none", md: "block" } }}>
                <ContactPage />
            </Box>
            <Box sx={{ display: { xs: "block", md: "none" } }}>
                <ContactPageSmall />
            </Box>
        </Box>
    );
}

export default ContactElement;
