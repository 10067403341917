//Components large

import Footer from "../largeDisplay/layout/Footer";

//MUI
import { Box } from "@mui/material";

function FooterElement() {
    return (
        <Box>
            <Box sx={{ display: { xs: "none", md: "block" } }}>
                <Footer />
            </Box>
        </Box>
    );
}

export default FooterElement;
