import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";

//MUI
import {
  AppBar,
  Box,
  Button,
  styled,
  Toolbar,
  Typography,
  useTheme,
} from "@mui/material";

//Components
import logo from "../../images/logo2.PNG";

const StyledToolbar = styled(Toolbar)({
  display: "flex",
  justifyContent: "space-between",
  height: "100px",
});

function NavBar() {
  const navigate = useNavigate();

  const theme = useTheme();

  const handleNavClick = (link) => {
    navigate(link);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <AppBar
      position="sticky"
      sx={{ backgroundColor: theme.palette.primary.main }}
    >
      <StyledToolbar>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
          width="100%"
        >
          <Button
            variant="text"
            disableElevation
            sx={{
              color: theme.palette.secondary.light,
              "&:hover": {
                color: theme.palette.secondary.main,
                opacity: 0.6,
              },
            }}
            onClick={() => {
              handleNavClick("/");
            }}
          >
            <Box component="img" src={logo} height="100px" alt="Volt BJJ" />
          </Button>
          <Box>
            <Button
              variant="text"
              disableElevation
              sx={{
                color: theme.palette.secondary.light,
                "&:hover": {
                  color: theme.palette.secondary.main,
                },
              }}
              onClick={() => {
                handleNavClick("/classes");
              }}
            >
              <Typography variant="h2" sx={{ fontSize: "18px" }}>
                NOS COURS
              </Typography>
            </Button>

            <Button
              variant="text"
              disableElevation
              sx={{
                color: theme.palette.secondary.light,
                "&:hover": {
                  color: theme.palette.secondary.main,
                },
              }}
              onClick={() => {
                handleNavClick("/coaches");
              }}
            >
              <Typography variant="h2" sx={{ fontSize: "18px" }}>
                Coachs
              </Typography>
            </Button>
            <Button
              variant="text"
              disableElevation
              sx={{
                color: theme.palette.secondary.light,
                "&:hover": {
                  color: theme.palette.secondary.main,
                },
              }}
              onClick={() => {
                handleNavClick("/galerie");
              }}
            >
              <Typography variant="h2" sx={{ fontSize: "18px" }}>
                GALERIE
              </Typography>
            </Button>
            <Button
              variant="text"
              disableElevation
              sx={{
                color: theme.palette.secondary.light,
                "&:hover": {
                  color: theme.palette.secondary.main,
                },
              }}
              onClick={() => {
                handleNavClick("/contact");
              }}
            >
              <Typography variant="h2" sx={{ fontSize: "18px" }}>
                CONTACT
              </Typography>
            </Button>
          </Box>
        </Box>
      </StyledToolbar>
    </AppBar>
  );
}

export default NavBar;
