//Components large

import CoachesPage from "../largeDisplay/pages/CoachesPage";

//Components small

import CoachesPageSmall from "../smallDisplay/pages/CoachesPageSmall";

//MUI
import { Box } from "@mui/material";

function CoachesElement() {
    return (
        <Box>
            <Box sx={{ display: { xs: "none", md: "block" } }}>
                <CoachesPage />
            </Box>
            <Box sx={{ display: { xs: "block", md: "none" } }}>
                <CoachesPageSmall />
            </Box>
        </Box>
    );
}

export default CoachesElement;
