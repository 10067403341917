import { createTheme } from "@mui/material";

export const theme = createTheme({
  palette: {
    primary: {
      main: "rgb(0,0,0)",
      light: "rgb(255,255,255)",
    },
    secondary: {
      light: "rgb(230,230,230)",
      main: "rgb(190,190,190)",
      dark: "rgb(150,150,150)",
    },
    otherColor: {
      main: "rgb(255,0,0)",
    },
  },
  typography: {
    fontFamily: [
      "Nunito",
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
    ].join(","),
  },
});
