//Components large

import ClassesPage from "../largeDisplay/pages/ClassesPage";

//Components small

import ClassesPageSmall from "../smallDisplay/pages/ClassesPageSmall";

//MUI
import { Box } from "@mui/material";

function ClassesElement() {
    return (
        <Box>
            <Box sx={{ display: { xs: "none", md: "block" } }}>
                <ClassesPage />
            </Box>
            <Box sx={{ display: { xs: "block", md: "none" } }}>
                <ClassesPageSmall />
            </Box>
        </Box>
    );
}

export default ClassesElement;
