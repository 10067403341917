//MUI
import { Box, Button, Typography } from "@mui/material";

//Components
import MapCustomSmall from "../contact/MapCustomSmall";

export default function ContactPageSmall() {
    return (
        <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            padding={3}
        >
            <Box
                width="100%"
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="flex-start"
            >
                <Typography
                    variant="h3"
                    sx={{ fontWeight: "bold", fontSize: "10vmin" }}
                >
                    Contactez-nous!
                </Typography>
                <br />
                <Typography sx={{ fontSize: "7vmin" }}>
                    Pour toutes questions concernant nos cours ou pour planifier
                    un cours d'essai, appelez-nous au:
                    <Button onClick={() => window.open("tel:5146680275")}>
                        <Typography
                            sx={{ fontWeight: "bold", fontSize: "7vmin" }}
                        >
                            514-668-0275
                        </Typography>
                    </Button>
                    Ou écrivez-nous au:
                    <Button
                        onClick={() => {
                            window.location.href = "mailto:sseguin007@live.ca";
                        }}
                    >
                        <Typography
                            sx={{ fontWeight: "bold", fontSize: "7vmin" }}
                        >
                            sseguin007@live.ca
                        </Typography>
                    </Button>
                </Typography>
                <br />
                <Typography sx={{ fontSize: "7vmin" }}>
                    Nous sommes situés au 1077 Chemin de Chambly à Longueuil
                    près du Cégep Édouard-Montpetit. N'hésitez pas à venir faire
                    un tour!
                </Typography>
            </Box>
            <MapCustomSmall />
        </Box>
    );
}
