import { Box, Typography, Unstable_Grid2 as Grid } from "@mui/material";

import samuelRacicotOr from "../../images/galery/mastermind_hawksbury_mars_2020/samuel_racicot_or.jpg";
import tommySambaultOr from "../../images/galery/mma_amateur_automne_2019/tommy_sambault_or.jpg";
import lievArgent from "../../images/galery/submission_art_united_17_fevrier_2024/liev_argent.jpg";
import anabelOr from "../../images/galery/open_quebec_2_mars_2024/anabel_or.jpg";
import lievOr from "../../images/galery/open_quebec_2_mars_2024/liev_or.jpg";
import teamOpenQuebec from "../../images/galery/open_quebec_2_mars_2024/team.jpg";

import allMatsuruCup from "../../images/galery/matsuru_cup_2_juin_2024/all.jpg";
import anabelOrMatsuruCup from "../../images/galery/matsuru_cup_2_juin_2024/anabel_or_gi.jpg";
import andreiOrMatsuruCup from "../../images/galery/matsuru_cup_2_juin_2024/andrei_or.jpg";
import linhBronzeGiMatsuruCup from "../../images/galery/matsuru_cup_2_juin_2024/linh_bronze_gi.jpg";
import noGiFemmeMatsuruCup from "../../images/galery/matsuru_cup_2_juin_2024/no_gi_femme.jpg";

export default function GaleriePageSmall() {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      width="100vw"
    >
      <Grid
        container
        spacing={2}
        rowSpacing={3}
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Grid xs={12} mt={4}>
          <Typography
            variant="h3"
            align="center"
            sx={{
              fontSize: "2rem",
            }}
          >
            Nos compétitions
          </Typography>
        </Grid>

        <Grid container xs={12} p={3}>
          {competitions2.map((competition, index) => (
            <Grid
              xs={12}
              key={index}
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
            >
              <Typography
                variant="h4"
                sx={{
                  fontSize: "1.5rem",
                }}
              >
                {competition.title}
              </Typography>
              <Typography variant="subtitle1">{competition.date}</Typography>
              <Typography variant="subtitle1">
                {competition.location}
              </Typography>

              <Grid
                container
                xs={12}
                mt={1}
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
              >
                {competition.images.map((image, ind) => (
                  <Grid
                    xs={12}
                    key={ind}
                    p={0}
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center"
                    mb={2}
                  >
                    <img
                      src={image.img}
                      alt={image.text}
                      width={250}
                      height={250}
                      style={{ objectFit: "cover" }}
                    />
                    <Typography>
                      {image.text} {image.type && `(${image.type})`}
                    </Typography>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          ))}
        </Grid>

        <Grid container xs={12}>
          {competitions1.map((competition, index) => (
            <Grid
              xs={12}
              key={index}
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
            >
              <Typography
                variant="h4"
                sx={{
                  fontSize: "1.5rem",
                }}
              >
                {competition.title}
              </Typography>
              <Typography variant="subtitle1">{competition.date}</Typography>
              <Typography variant="subtitle1">
                {competition.location}
              </Typography>

              <Grid
                container
                xs={12}
                mt={1}
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
              >
                {competition.images.map((image, ind) => (
                  <Grid
                    xs={12}
                    key={ind}
                    p={0}
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <img
                      src={image.img}
                      alt={image.text}
                      width={250}
                      height={250}
                      style={{ objectFit: "cover" }}
                    />
                    <Typography>
                      {image.text} {image.type && `(${image.type})`}
                    </Typography>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Box>
  );
}

const matsuruCup = {
  title: "Matsuru Cup",
  date: "2 Juin 2024",
  location: "Longueuil, Québec",

  images: [
    {
      text: "Nos champions!",
      img: allMatsuruCup,
    },

    {
      text: "Anabel - médaille d'or",
      img: anabelOrMatsuruCup,
      type: "gi",
    },

    {
      text: "Andrei - médaille d'or",
      img: andreiOrMatsuruCup,
      type: "gi",
    },

    {
      text: "Linh - médaille de bronze",
      img: linhBronzeGiMatsuruCup,
      type: "gi",
    },

    {
      text: "Anabel - argent, Linh - bronze",
      img: noGiFemmeMatsuruCup,
      type: "no-gi",
    },
  ],
};

const mastermindHawksburry = {
  title: "Mastermind",
  date: "Mars 2020",
  location: "Hawksburry, Ontario",

  images: [
    {
      text: "Samuel Racicot - médaille d'or",
      img: samuelRacicotOr,
      type: "gi",
    },
  ],
};

const mmaAmateurAutomne2019 = {
  title: "MMA Amateur",
  date: "Automne 2019",
  location: "Montréal, Québec",

  images: [
    {
      text: "Tommy Sambault - médaille d'or",
      img: tommySambaultOr,
      type: "gi",
    },
  ],
};

const submissionArtUnited17Fevrier2024 = {
  title: "Submission Arts United",
  date: "17 Février 2024",
  location: "Montréal, Québec",

  images: [
    {
      text: "Liev Kolbin - médaille d'argent",
      img: lievArgent,
      type: "no-gi",
    },
  ],
};

const openQuebec2Mars2024 = {
  title: "Open Québec",
  date: "2 Mars 2024",
  location: "Montréal, Québec",

  images: [
    {
      text: "Anabel Becerra - médaille d'or",
      img: anabelOr,
      type: "gi",
    },
    {
      text: "Liev Kolbin - médaille d'or",
      img: lievOr,
      type: "no-gi",
    },
    {
      text: "Nos champions!",
      img: teamOpenQuebec,
    },
  ],
};

const competitions1 = [
  submissionArtUnited17Fevrier2024,
  mastermindHawksburry,
  mmaAmateurAutomne2019,

  // openQuebec2Mars2024,
];

const competitions2 = [matsuruCup, openQuebec2Mars2024];
