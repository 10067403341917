//MUI
import {
  Box,
  Typography,
  useTheme,
  styled,
  Button,
  IconButton,
} from "@mui/material";
import { Instagram as InstagramIcon } from "@mui/icons-material";

export default function Footer() {
  const theme = useTheme();

  const StyledTypography = styled(Typography)(({ theme }) => ({
    color: theme.palette.primary.light,
  }));

  return (
    <Box
      backgroundColor={theme.palette.primary.main}
      sx={{
        display: "flex",

        flexDirection: "row",
        justifyContent: "space-around",
      }}
      padding={3}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <StyledTypography align="center" variant="h6">
          Nous Contacter
        </StyledTypography>
        <Button onClick={() => window.open("tel:5146680275")}>
          <StyledTypography
            align="center"
            sx={{
              "&:hover": {
                color: theme.palette.secondary.main,
                cursor: "pointer",
              },
            }}
          >
            514-668-0275
          </StyledTypography>
        </Button>
        <Button
          onClick={() => {
            window.location.href = "mailto:sseguin007@live.ca";
          }}
        >
          <StyledTypography
            align="center"
            sx={{
              "&:hover": {
                color: theme.palette.secondary.main,
                cursor: "pointer",
              },
            }}
          >
            sseguin007@live.ca
          </StyledTypography>
        </Button>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <StyledTypography
          variant="h5"
          sx={{
            textDecoration: "underline",
          }}
          align="center"
        >
          VOLT BJJ
        </StyledTypography>
        <br />
        <StyledTypography align="center">
          1077 Chemin de Chambly, Longueuil, Qc, Ca
        </StyledTypography>
        <br />
        <IconButton
          sx={{
            color: theme.palette.primary.light,
            "&:hover": {
              color: theme.palette.secondary.main,
              cursor: "pointer",
            },
            width: "fit-content",
            alignSelf: "center",
          }}
          size="large"
          onClick={() => {
            window.open("https://www.instagram.com/volt.bjj");
          }}
        >
          <InstagramIcon />
        </IconButton>
        {/*<StyledTypography align="center">
                    Propulsé par WIGGLYWEB DESIGN
                </StyledTypography>*/}
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <StyledTypography align="center" variant="h6">
          Horaire
        </StyledTypography>
        <StyledTypography align="center">Lundi: 19h30 - 21h00</StyledTypography>
        <StyledTypography align="center">
          Mercredi: 19h30 - 21h00
        </StyledTypography>
        <StyledTypography align="center">Jeudi: 19h30 - 21h00</StyledTypography>
        <StyledTypography align="center">
          Vendredi: 19h30 - 21h00
        </StyledTypography>
        <StyledTypography align="center">
          Dimanche: 14h00 - 15h30
        </StyledTypography>
      </Box>
    </Box>
  );
}
